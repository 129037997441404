<template>
  <tbody>
    <tr class="text-center">
      <td>{{ $t("labels.bill") }}</td>
      <td>
        <SelectMultipleCustomerByWarehouse
          :multiple="false"
          :label="$t('labels.customer')"
          :placeholder="$t('labels.customer')"
          name="id_customers"
          @onFilter="onFilterChange"
        />
      </td>
      <td>
        <DateRangeFilter
          :disabled="isDisabledDateRange"
          :min-date="minDate"
          :label="$t('labels.from_to')"
          :placeholder="$t('labels.from_to')"
          :default-date="filters.dates"
          name="dates"
          @onFilter="onFilterChange"
        />
      </td>
      <td>
        <v-btn color="success" :disabled="isDisabledAction" @click="exportExcel"
          ><v-icon>mdi-download</v-icon></v-btn
        >
      </td>
      <td></td>
    </tr>
  </tbody>
</template>

<script>
import moment from "moment";
import { defaultDataDate } from "@/libs/helpers";

export default {
  name: "Bill",
  components: {
    SelectMultipleCustomerByWarehouse: () =>
      import("@/components/administration/SelectMultipleCustomerByWarehouse"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
  },
  data: () => ({
    filters: {
      id_customers: [],
      dates: [],
    },
    minDate: null,
    isLoading: false,
  }),
  computed: {
    isDisabledDateRange() {
      return (
        !this.filters.id_customers || this.filters.id_customers.length === 0
      );
    },
    isDisabledAction() {
      return (
        this.isDisabledDateRange ||
        !this.filters.dates ||
        this.filters.dates.length === 0
      );
    },
  },
  created() {
    let startOfSubMonth = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    let endOfSubMonth = moment()
      .subtract(1, "month")
      .endOf("month")
      .format("YYYY-MM-DD");
    const { startDate, endDate, minDate } = defaultDataDate({
      startDate: startOfSubMonth,
      endDate: endOfSubMonth,
      warehouses: (window.me && window.me.warehouses) || [],
    });
    this.minDate = minDate;
    this.filters = { ...this.filters, dates: [startDate, endDate] };
  },
  methods: {
    onFilterChange(filter) {
      let values = filter.value;
      if (["string", "number"].includes(typeof filter.value)) {
        values = [filter.value];
      }
      this.filters = { ...this.filters, [filter.name]: values };
    },
    async exportExcel() {
      this.$emit("exportExcel", { params: this.filters, type: "bill" });
    },
  },
};
</script>

<style scoped></style>
